import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';

import LinkButton from '../LinkButton';

import classes from './MultilineEllipses.module.scss';
import Typography from '../Typography';

const MultilineEllipses = ({
  children,
  lines,
  linkProps,
  onClick,
  bgColor,
  showReadMoreAlways = false,
  isEmbedded = false,
  showRightChevron = true,
  styles = {},
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const [isTextTruncated, setTextTruncate] = useState(false);
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef?.current) {
      if (lines === 1) {
        setTextTruncate(contentRef?.current?.scrollWidth > contentRef?.current?.offsetWidth);
      } else {
        setTextTruncate(contentRef?.current?.scrollHeight > contentRef?.current?.offsetHeight);
      }
    }
  }, [lines, contentRef?.current?.scrollHeight, contentRef?.current?.scrollWidth]);

  return (
    <div className={classes.root}>
      <div ref={contentRef} className={classes.truncate} style={{ WebkitLineClamp: lines }}>
        {children}
      </div>
      {(isTextTruncated || showReadMoreAlways) && (
        <div
          className={classnames(classes.anchor, { [classes.isEmbedded]: isEmbedded, [styles.anchor]: !!styles.anchor })}
          style={{ backgroundColor: bgColor }}
        >
          {linkProps && (
            <LinkButton
              isLink
              variant="text"
              {...linkProps}
              rightIcon={showRightChevron && <NavigateNextOutlinedIcon />}
            >
              {t(`${TP}.FN_READ_MORE`)}
            </LinkButton>
          )}
          {onClick && (
            <Typography size="12" className={classes.readMore} onClick={onClick}>
              {`${t(`${TP}.FN_READ_MORE`)}`}
              {showRightChevron && <NavigateNextOutlinedIcon />}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default MultilineEllipses;
