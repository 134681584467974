import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useTheme, { THEMES } from 'components/Globals/Layout/ThemeProvider';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import MultilineEllipses from 'components/uiLibrary/MultilineEllipses';
import LinkButton from 'components/uiLibrary/LinkButton';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';
import EntityName from 'components/Globals/EntityName';
import { useReviewDetails } from 'utils/reviews';
import { DATE_FORMATS, ENTITY_TYPE, ENTITY_TYPE_MAP } from 'constants/index';
import { createDate } from 'utils/date';

import { getProductionCredits, getProductionTitle } from 'utils/productions';
import { getComposersFromCreators } from 'utils/composer';

import classes from './ReviewCard.module.scss';

export const ReviewProductionInfo = ({ production, className, trackingData }) => {
  const { title, composers, producers, conductors, directors } = useMemo(
    () => ({
      title: getProductionTitle(production),
      composers: getComposersFromCreators(production?.productionWorks?.[0]?.work?.creators),
      ...getProductionCredits(production),
    }),
    [production],
  );

  return (
    <div
      className={classnames(classes.productionContainer, {
        [className]: !!className,
      })}
    >
      <div className={classes.productionContainer__info}>
        {title && (
          <Typography variant="span" size="12" weight="upper-medium" color="secondary" truncate>
            <EntityName entityType={ENTITY_TYPE.PRODUCTION} entity={{ id: production?.id, name: title }} isRaw />
          </Typography>
        )}
        {composers?.length > 0 && (
          <>
            <Typography variant="span" size="12" italic truncate>
              {' '}
              <EntityName
                entityType={ENTITY_TYPE.ARTIST}
                entity={{
                  id: composers[0]?.profile?.id,
                  name: composers[0]?.profile?.shortName || composers[0]?.profile?.name,
                }}
                isRaw
                trackingData={trackingData}
              />
            </Typography>
            {composers.length > 1 && (
              <Typography size="12" color="secondary">
                {` (+${composers.length - 1})`}
              </Typography>
            )}
          </>
        )}
        {producers?.data?.length > 0 && (
          <>
            <Typography variant="span" weight="upper-medium" size="12" color="secondary" truncate>
              {' | '}
              <EntityName
                entity={producers.data[0].entity}
                key={producers.data[0].entity?.id}
                entityType={producers.data[0].entityType}
                isRaw
                trackingData={trackingData}
              />
            </Typography>
            {producers.data.length > 1 && (
              <Typography weight="upper-medium" size="12" color="secondary">
                {` (+${producers.data.length - 1})`}
              </Typography>
            )}
          </>
        )}
      </div>
      <div className={classes.credits}>
        {directors?.data?.length > 0 && (
          <>
            <Typography size="12" color="secondary" truncate>
              D:{' '}
              <EntityName
                key={directors.data[0]?.entity?.id}
                entityType={ENTITY_TYPE_MAP[directors.data[0]?.entityType]}
                entity={directors.data[0]?.entity}
                isRaw
                trackingData={trackingData}
              />
            </Typography>
            {directors.data.length > 1 && (
              <Typography size="12" color="secondary">
                {` (+${directors.data.length - 1})`}
              </Typography>
            )}
          </>
        )}
        {conductors?.data?.length > 0 && (
          <>
            <Typography size="12" color="secondary" truncate>
              C:{' '}
              <EntityName
                key={conductors.data[0]?.entity?.id}
                entityType={ENTITY_TYPE_MAP[conductors.data[0]?.entityType]}
                entity={conductors.data[0]?.entity}
                isRaw
                trackingData={trackingData}
              />
            </Typography>
            {conductors.data.length > 1 && (
              <Typography size="12" color="secondary">
                {` (+${conductors.data.length - 1})`}
              </Typography>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ReviewCard = ({ review, onClick, withProductionInfo = false, trackingData, styles }) => {
  const details = useReviewDetails(review);
  const isProductionExist = withProductionInfo && review?.production?.id;
  const track = useTracking();
  const { theme } = useTheme();

  const handleReviewClick = () => {
    onClick(review);
    track.click({
      ...trackingData,
      entityId: review?.id,
      entityName: details?.title,
      component: COMPONENTS.REVIEW_CARD,
      subComponent: SUB_COMPONENTS.REVIEW_CONTENT,
    });
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.root__withProduction]: isProductionExist,
        [styles?.root]: styles?.root,
      })}
    >
      <div className={classes.reviewCard}>
        <div
          className={classnames(classes.reviewCard__content, {
            [classes.reviewCard__content_withProdInfo]: isProductionExist,
          })}
        >
          <div
            className={classes.review}
            role="button"
            tabIndex={0}
            onKeyDown={handleReviewClick}
            onClick={handleReviewClick}
          >
            <SpriteIcon icon="quotes_open" className={classes.quotes_open} />
            {details?.content && (
              <MultilineEllipses
                showRightChevron={false}
                theme={theme}
                styles={{
                  anchor: classes.readMore,
                }}
                onClick={handleReviewClick}
              >
                <Typography
                  variant="q"
                  className={classnames(classes.review_content, {
                    [classes.review_content_withProdInfo]: isProductionExist,
                  })}
                  size={theme === THEMES.DARK ? 16 : 14}
                  color={theme === THEMES.DARK ? 'white' : 'primary'}
                  weight={theme === THEMES.DARK ? 'light' : 'regular'}
                >
                  {details?.excerpt}
                </Typography>
              </MultilineEllipses>
            )}
          </div>
          <div className={classes.metaInfo}>
            {details?.reviewDate && (
              <Typography
                className={classes.metaInfo_date}
                size="12"
                color={theme === THEMES.DARK ? 'tertiary' : 'secondary'}
              >
                {createDate(`${details?.reviewDate}`).format(DATE_FORMATS.DATE_LABEL)}
              </Typography>
            )}

            <div className={classes.metaInfo_basic}>
              {details?.author && (
                <Typography
                  size="12"
                  className={classes.metaInfo_author}
                  color={theme === THEMES.DARK ? 'tertiary' : 'secondary'}
                  italic
                  truncate
                >
                  {details?.author} {details?.hostname || ''}
                </Typography>
              )}
              {details?.link && (
                <LinkButton
                  isLink
                  variant="text"
                  href={details?.link}
                  styles={{ root: classes.metaInfo_link }}
                  rightIcon={<SpriteIcon icon="open_in_new" size={12} />}
                  external
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.REVIEW_CARD,
                    subComponent: SUB_COMPONENTS.REVIEW_LINK,
                    meta: { ...trackingData?.meta, link: details?.link },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isProductionExist && <ReviewProductionInfo production={review.production} trackingData={trackingData} />}
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.object,
};

export default React.memo(ReviewCard);
